<template>
    <div class="date_Not_Available">
        <div class="date_Not_Available_tittle">
            <i class="icon-mks icon_alert_date_calendar"></i>
            <span>¡No tenemos disponibilidad para esta fecha!</span>
            <i class="icon-mks icon_alert_date_close" @click="$parent.close()"></i>
        </div>
        <div class="date_Not_Available_detail">
            <div class="date_Not_Available_detail_info">Selecciona la próxima fecha disponible para {{ selectedPeople }} pax</div>
            <div class="date_Not_Available_detail_options" :class="{'mb-20': (parseInt(params.activateWaitingList) === 0 || !params.activateWaitingList)}">
                <div class="date_Not_Available_detail_options_item disabled">
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                        <path d="M11.5 0C9.22552 0 7.00211 0.674463 5.11095 1.9381C3.21978 3.20174 1.7458 4.99779 0.87539 7.09914C0.00498279 9.20049 -0.222756 11.5128 0.220974 13.7435C0.664704 15.9743 1.75997 18.0234 3.36828 19.6317C4.97658 21.24 7.02568 22.3353 9.25646 22.779C11.4872 23.2228 13.7995 22.995 15.9009 22.1246C18.0022 21.2542 19.7983 19.7802 21.0619 17.8891C22.3255 15.9979 23 13.7745 23 11.5C22.9967 8.45102 21.784 5.52787 19.6281 3.37191C17.4721 1.21596 14.549 0.0032977 11.5 0V0ZM11.5 1.91667C13.7104 1.9149 15.8527 2.68118 17.5605 4.08442L4.08442 17.5605C2.93496 16.1573 2.20768 14.4566 1.98722 12.6562C1.76676 10.8557 2.0622 9.02974 2.83915 7.3907C3.6161 5.75166 4.84259 4.36701 6.37587 3.39791C7.90915 2.42881 9.68614 1.91513 11.5 1.91667ZM11.5 21.0833C9.28964 21.0851 7.14732 20.3188 5.4395 18.9156L18.9156 5.4395C20.065 6.84265 20.7923 8.54342 21.0128 10.3438C21.2332 12.1443 20.9378 13.9703 20.1609 15.6093C19.3839 17.2483 18.1574 18.633 16.6241 19.6021C15.0909 20.5712 13.3139 21.0849 11.5 21.0833Z"/>
                    </svg>
                    <span class="text">
                        Fecha no disponible
                    </span>
                    <span class="text-2">
                        {{ datePrevius }}
                    </span>
                </div>
                <div class="date_Not_Available_detail_options_item active" @click="closeHourNext()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="23" viewBox="0 0 26 23" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.16442 19.6219C6.38105 21.8015 9.33276 23 12.4782 23C15.618 23 18.564 21.8071 20.7692 19.6386C22.9972 17.4646 24.2224 14.5826 24.2224 11.5056V10.5245C24.2224 9.98934 23.7836 9.55453 23.2309 9.55453C22.6838 9.55453 22.2394 9.98376 22.2394 10.5245V11.5056C22.2394 14.0587 21.2251 16.4556 19.3788 18.2618C17.5269 20.0623 15.0823 21.0545 12.4725 21.0545H12.4668C7.08194 21.049 2.69996 16.7622 2.70566 11.4944C2.70566 8.94135 3.72565 6.54435 5.57189 4.73267C7.42384 2.93214 9.8684 1.93989 12.4782 1.93989H12.4839C13.8572 1.93989 15.1963 2.22419 16.4499 2.77048C16.9514 2.99346 17.5383 2.77048 17.7605 2.27993C17.9885 1.78938 17.7605 1.21522 17.2591 0.997819C15.7433 0.340039 14.1364 0 12.4839 0H12.4782C9.33846 0 6.39245 1.19292 4.17582 3.36137C1.94779 5.52981 0.722656 8.41735 0.722656 11.4889C0.722656 14.5604 1.94209 17.4479 4.16442 19.6219ZM11.7761 14.2986C11.9698 14.4882 12.2263 14.5829 12.477 14.5829C12.7277 14.5829 12.9841 14.4882 13.1779 14.2986L25.0246 2.72058C25.4121 2.34152 25.4121 1.72276 25.0246 1.3437C24.6371 0.964643 24.0046 0.964643 23.6171 1.3437L12.477 12.2361L9.95265 9.76106C9.56516 9.382 8.93265 9.382 8.54517 9.76106C8.15769 10.1401 8.15769 10.7589 8.54517 11.1379L11.7761 14.2986Z"/>
                    </svg>
                    <span class="text">
                        Próxima fecha disponible
                    </span>
                    <span class="text-2">
                        {{ dateNext }}
                    </span>
                </div>
            </div>
            <div class="date_Not_Available_detail_description" v-if="parseInt(params.activateWaitingList) === 1 && (parseInt(info.isWaitinglistBlocked) === 0 || info.isWaitinglistBlocked === undefined || !info.isWaitinglistBlocked)">O si deseas registrarte en la lista de espera para el&nbsp;<strong>{{ datePrevius }}</strong>&nbsp;debes seleccionar la hora de la reserva</div>
            <div class="date_Not_Available_detail_action" v-if="parseInt(params.activateWaitingList) === 1 && (parseInt(info.isWaitinglistBlocked) === 0 || info.isWaitinglistBlocked === undefined || !info.isWaitinglistBlocked)">
                <button @click="closeHour()">
                    <span>Seleccionar hora</span>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  props: ['datePrevius', 'dateNext', 'dateIn', 'dateOut', 'info'],
  methods: {
    closeHour () {
      this.$emit('accept', this.dateIn);
      this.$parent.close();
    },
    closeHourNext () {
      this.$emit('nextAccept', this.dateOut);
      this.$parent.close();
    }
  }
};
</script>
<style lang="scss">
.date_Not_Available {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 549px;
    box-shadow: 20px 20px 20px 0px rgba(0, 0, 0, 0.50);
    margin: 0 auto;
    &_tittle {
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--cardBackgroundColor);
        height: 69px;
        width: 100%;
        border-radius: 10px 10px 0px 0px;
        position: relative;
        span {
            width: 213px;
            color: var(--color1);;
            text-align: center;
            font-family: 'Source Sans Pro';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            user-select: none;
        }
    }
    &_detail {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 0px 0px 10px 10px;
        background: #fff;
        width: 100%;
        &_info {
            margin: 17px 0px 17px 0px;
            color: #000;
            text-align: center;
            font-family: 'Source Sans Pro';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            user-select: none;
        }
        &_description {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #000;
            text-align: center;
            font-family: 'Source Sans Pro';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 20px 0px 14px 0px;
            flex-wrap: wrap;
            width: 370px;
            user-select: none;
        }
        &_action {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            button {
                border-radius: 6px;
                border: 1px solid #000;
                background: #fff;
                box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.20);
                width: 250px;
                height: 40px;
                flex-shrink: 0;
                cursor: pointer;
                span {
                    color: #000;
                    text-align: center;
                    font-family: 'Source Sans Pro';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
                &:hover {
                    background: #000;
                    span {
                        color: #fff;
                    }
                }
            }
        }
        &_options {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 40px;
            .disabled {
                background: #fff;
                cursor: no-drop;
                path {
                    fill: #000;
                }
            }
            .active {
                border: 1px solid #000;
                background: #fff;
                box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.20);
                cursor: pointer;
                path {
                    fill: #000;
                }
                &:hover {
                    background: #000;
                    span {
                        color: #fff;
                    }
                    path {
                        fill: #fff;
                    }
                }
            }
            &_item {
                width: 193px;
                height: 93px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                flex-direction: column;
                .text {
                    color: #000;
                    font-family: 'Source Sans Pro';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-top: 4px;
                    user-select: none;
                }
                .text-2 {
                    margin-top: 4px;
                    color: #000;
                    font-family: 'Source Sans Pro';
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    user-select: none;
                }
            }
        }
        .mb-20 {
            margin-bottom: 20px;
        }
    }
}
@media screen and (max-width: 500px) {
    .date_Not_Available {
        margin: 0 auto;
        width: calc(100% - 20px);
        .icon_alert_date_calendar {
            margin-right: 15px;
        }
        .date_Not_Available_detail_options_item {
            width: 160px;
        }
        .text {
            font-size: 14px;
        }
        .text-2 {
            font-size: 16px;
        }
    }
}
@media screen and (min-width: 501px) and (max-width: 560px) {
    .date_Not_Available {
        margin: 0 auto;
        width: calc(100% - 20px);
        .date_Not_Available_detail_options_item {
            width: 176px;
        }
        .text {
            font-size: 14px;
        }
        .text-2 {
            font-size: 16px;
        }
    }
}
@media screen and (max-width: 500px) {
    .date_Not_Available {
        .date_Not_Available_tittle .icon_alert_date_close {
            right: 10px;
        }
        .date_Not_Available_detail_description {
            width: 360px;
        }
        .date_Not_Available_detail_options {
            gap: 14px;
        }
    }
}
</style>
