import { render, staticRenderFns } from "./gairaMap.vue?vue&type=template&id=0fa5c2b4&scoped=true&class=Big-Mapa"
import script from "./gairaMap.vue?vue&type=script&lang=js"
export * from "./gairaMap.vue?vue&type=script&lang=js"
import style0 from "./gairaMap.vue?vue&type=style&index=0&id=0fa5c2b4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fa5c2b4",
  null
  
)

export default component.exports