<template>
  <div class="width-100">
    <p
      :class="{'People-subtitle': editPeople,'Reservation-subtitle': !editPeople}"
    >{{ $t('numberDiners') }}</p>
    <div class="Reservation-divider" v-if="!showNewPeople"></div>
    <div class="People">
      <!--RESERVAS NORMALES-->
      <!--NUEVO-->
      <div class="people-quantity-new people-quantity-new__height-default" v-if="selectedTypeReservation !== 'Pombo' && params.activeSelectorBoy !== 1 && params.activeSelectorBaby !== 1">
        <div class="people-quantity-new__container" :class="gridColumnsAuto()">
          <div class="people-quantity-new__container--action" :class="{'people-quantity-new__container-edit': editPeople}" v-if="flagForDeletePeople" @click="decrementPeopleNew()">
            <i class="icon-mks icon-minus-new"></i>
          </div>
          <div v-for="(people, index) in arrayPeopleForSelect" :key="index" :style="{backgroundColor: currentBackground(people.value)}" @click="choosePeople(people.value)" :class="{'people-quantity-new__container--selected' : selectedPeople === people.value, 'people-quantity-new__container-edit': editPeople}">
            <span>{{people.value}}</span>
          </div>
          <div class="people-quantity-new__container--action" :class="{'people-quantity-new__container--action__disabled' : !flagForAddPeople, 'people-quantity-new__container-edit': editPeople}" @click="incrementPeopleNew()">
            <i class="icon-mks icon-plus-new"></i>
          </div>
        </div>
      </div>

      <div class="people-quantity-new people-quantity-new-adult" :class="{'people-quantity-new-adult-boys' : params.activeSelectorBoy == 1 && params.activeSelectorBaby == 1}" v-if="params.activeSelectorBoy == 1 || params.activeSelectorBaby == 1">
        <div>
          <h3 :class="{'title-edit-reserve': editPeople}">Adultos</h3>
          <div class="people-quantity-new__container" :class="gridColumnsAutoBoysAdults()">
            <div class="people-quantity-new__container--action" :class="{'people-quantity-new__container-edit': editPeople}" v-if="flagForDeletePeople" @click="decrementPeopleNew('adult')">
              <i class="icon-mks icon-minus-new"></i>
            </div>
            <div v-for="(people, index) in arrayPeopleForSelect" :style="{backgroundColor: currentBackgroundGeneral(people.value, 'adult')}" :key="index" @click="choosePeople(people.value, 'adult')" :class="{'people-quantity-new__container--selected' : selectedAdult === people.value, 'people-quantity-new__container-edit': editPeople}">
              <span>{{people.value}}</span>
            </div>
            <div class="people-quantity-new__container--action" :class="{'people-quantity-new__container--action__disabled' : !flagForAddPeople, 'people-quantity-new__container-edit': editPeople}" @click="incrementPeopleNew('adult')">
              <i class="icon-mks icon-plus-new"></i>
            </div>
          </div>
        </div>
        <div :class="{'people-quantity-new-adult-boys__two-columns' : params.activeSelectorBoy == 1 && params.activeSelectorBaby == 1}">
          <div v-if="params.activeSelectorBoy == 1">
            <h3 :class="{'title-edit-reserve': editPeople}">Niños</h3>
            <div class="people-quantity-new__container" :class="computedClassBoys()">
              <div class="people-quantity-new__container--action" :class="{'people-quantity-new__container-edit': editPeople}" v-if="flagForDeletePeopleBoys" @click="decrementPeopleNew('boys')">
                <i class="icon-mks icon-minus-new"></i>
              </div>
              <div v-for="(people, index) in arrayPeopleForSelectBoys" :style="{backgroundColor: currentBackgroundGeneral(people.value, 'boy')}" :key="index" @click="choosePeople(people.value, 'boys')" :class="{'people-quantity-new__container--selected' : selectedBoy === people.value, 'people-quantity-new__container-edit': editPeople}">
                <span>{{people.value}}</span>
              </div>
              <div class="people-quantity-new__container--action" :class="{'people-quantity-new__container--action__disabled' : !flagForAddPeopleBoys, 'people-quantity-new__container-edit': editPeople}" @click="incrementPeopleNew('boys')">
                <i class="icon-mks icon-plus-new"></i>
              </div>
            </div>
          </div>
          <div v-if="params.activeSelectorBaby == 1">
            <h3 :class="{'title-edit-reserve': editPeople}">Bebés</h3>
            <div class="people-quantity-new__container" :class="computedClassBabys()">
              <div class="people-quantity-new__container--action" :class="{'people-quantity-new__container-edit': editPeople}" v-if="flagForDeletePeopleBabys" @click="decrementPeopleNew('babys')">
                <i class="icon-mks icon-minus-new"></i>
              </div>
              <div v-for="(people, index) in arrayPeopleForSelectBabys" :style="{backgroundColor: currentBackgroundGeneral(people.value, 'baby')}" :key="index" @click="choosePeople(people.value, 'babys')" :class="{'people-quantity-new__container--selected' : selectedBaby === people.value, 'people-quantity-new__container-edit': editPeople}">
                <span>{{people.value}}</span>
              </div>
              <div class="people-quantity-new__container--action" :class="{'people-quantity-new__container--action__disabled' : !flagForAddPeopleBabys, 'people-quantity-new__container-edit': editPeople}" @click="incrementPeopleNew('babys')">
                <i class="icon-mks icon-plus-new"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--FIN NUEVO-->
    </div>
    <!--RESERVAS POMBO-->
    <div v-if="selectedTypeReservation === 'Pombo'">
      <!--{{ $t('adults') }}-->
      <div class="columns">
        <p
          :class="{'sub-tittle-edit': editPeople, 'sub-tittle': !editPeople}"
          class="column"
        >{{ $t('adults') }}</p>
        <div class="column num-people">
          <button
            :class="{'btn-num-edit': editPeople, 'btn-num': !editPeople}"
            :disabled="(parseInt(selectedAdult) == parseInt(dataReservationEdit.adult)) || ( selectedAdult == params.allowSingleReservations )"
            @click="decrementPeoplePombo('remove', 'adult')"
            type="button"
          >-</button>
          <div
            :class="{'text-num': editPeople,'Reservation-big-text': !editPeople}"
          >{{ selectedAdult }}</div>
          <button
            :class="{'btn-num-edit': editPeople, 'btn-num': !editPeople}"
            :disabled="(selectedPeople == limitedPeople(params)) ||( selectedAdult == limitedPeople(params)) "
            @click="decrementPeoplePombo('add', 'adult')"
            type="button"
          >+</button>
        </div>
      </div>
      <!--{{ $t('kids') }}-->
      <div class="columns">
        <p
          :class="{'sub-tittle-edit': editPeople, 'sub-tittle': !editPeople}"
          class="column"
        >{{ $t('kids') }}</p>
        <div class="column num-people">
          <button
            :class="{'btn-num-edit': editPeople, 'btn-num': !editPeople}"
            :disabled="(parseInt(selectedBoy) == parseInt(dataReservationEdit.boy)) || (selectedBoy == 0)"
            @click="decrementPeoplePombo('remove', 'boy')"
            type="button"
          >-</button>
          <div
            :class="{'text-num':editPeople,'Reservation-big-text': !editPeople}"
          >{{ selectedBoy }}</div>
          <button
            :class="{'btn-num-edit': editPeople, 'btn-num': !editPeople}"
            :disabled="selectedPeople == limitedPeople(params) || ( selectedAdult == limitedPeople(params)) "
            @click="decrementPeoplePombo('add', 'boy')"
            type="button"
          >+</button>
        </div>
      </div>
      <!--MENORES-->
      <div class="columns">
        <p
          :class="{'sub-tittle-edit': editPeople, 'sub-tittle': !editPeople}"
          class="column"
        >{{ $t('kids3Years') }}</p>
        <div class="column num-people">
          <button
            :class="{'btn-num-edit': editPeople, 'btn-num': !editPeople}"
            :disabled="(parseInt(selectedBaby) == parseInt(dataReservationEdit.baby)) || (selectedBaby == 0)"
            @click="decrementPeoplePombo('remove', 'baby')"
            type="button"
          >-</button>
          <div
            :class="{'text-num':editPeople,'Reservation-big-text': !editPeople}"
          >{{ selectedBaby }}</div>
          <button
            :class="{'btn-num-edit': editPeople, 'btn-num': !editPeople}"
            :disabled=" selectedPeople == limitedPeople(params) || ( selectedAdult == limitedPeople(params))"
            @click="decrementPeoplePombo('add', 'baby')"
            type="button"
          >+</button>
        </div>
      </div>
    </div>
    <div class="sub-text" v-if="params.vendorId !== 288">
      <span
        :class="{'Reservation-subtitle': !editPeople, 'text-parafe':editPeople}"
        v-if="isFree && (selectedTypeReservation !== 'Rumba' && selectedTypeReservation !== 'Cena') && eventPrice == 0"
      >
        <p v-if="(params.vendorId !== 160) || (params.vendorId == 160 && selectedPeople !== 2) ">
          <span v-html="messagePeople[$i18n.locale]"></span>&nbsp;
          <a
            :class="{'Reservation-subtitle link':!editPeople,'linker':editPeople}"
            @click="handleClick"
            href="#"
          >
            <b v-if="isAndresDc()">Contáctanos</b>
            <b v-else-if="params.vendorId !== 167">{{ $t('contactEvent') }}</b>
            <b v-else>{{ $t('contact11And9') }}</b>
          </a>
        </p>
        <p v-if="params.vendorId == 160 && selectedPeople == 2">{{ $t(`locateBar`) }}.</p>
      </span>
      <span
        :class="{'Reservation-subtitle': !editPeople, 'text-parafe':editPeople }"
        v-if="!isFree && (selectedTypeReservation !== 'Rumba' && selectedTypeReservation !== 'Cena' && selectedTypeReservation !== 'Pombo' && params.vendorId !== 150 && params.vendorId !== 149) && eventPrice == 0"
      >
        <span>{{params.consumableCopy}}</span>
        <p v-if="params.vendorId== 16 && selectedPeople >= 10">{{ $t('peopleRecommend10') }}.</p>
      </span>
      <span
        :class="{'Reservation-subtitle':!editPeople, 'col-flex': editPeople}"
        v-if="!isFree && (selectedTypeReservation === 'Rumba' || selectedTypeReservation == 'Cena') && eventPrice == 0"
      >
        <span v-if="selectedTypeReservation === 'Rumba'">
          <b>{{ $t('contributionArtist') }}</b>
        </span>
        <span v-if="selectedTypeReservation === 'Cena'">
          <b>{{ $t('contributionArtist') }}</b>.
        </span>
      </span>
      <div
        class="gaira-sub-text"
        v-if="!isFree && params.vendorId == 72 && selectedTypeReservation === 'Pombo' && eventPrice == 0 "
      >
        <span :class="{'Reservation-subtitle':!editPeople, 'col-edit': editPeople}">
          {{ $t('advanceReservation1') }}
          <b>{{ schedules['pombo'][0].purchaseAdult | currency('$ ', 0, { thousandsSeparator: '.' }) }}</b>
          {{ $t('advanceReservation2') }}
          <b>{{ schedules['pombo'][0].purchaseBoy | currency('$ ', 0, { thousandsSeparator: '.' }) }}</b>
          {{ $t('advanceReservation3') }}
          <b>{{ $t('breakfastShow') }}</b>.
        </span>
      </div>
      <div v-if="eventPrice > 0 ">
        <span
          :class="{'Reservation-subtitle':!editPeople, 'col-edit': editPeople}"
          v-html="`${getTextEvent()}`"
        ></span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import eventTypes from '@/store/types/event';
/* import PeopleSelector from '@/components/Select/people/peopleSelector'; */
export default {
  name: 'People',
  props: ['isFrees', 'peoples', 'showTitle', 'colorPrimary', 'editPeople', 'maxPeople', 'infoReservation', 'useNewPeople'],
  data () {
    return {
      minimumChargedPeople: null,
      people: null,
      messagePeople: {},
      showNewPeople: false,
      initialPeople: 11,
      arrayPeopleForSelect: [],
      arrayPeopleForSelectBoys: [],
      arrayPeopleForSelectBabys: [],
      counterBoys: 3,
      counterBabys: 3,
      flagForAddPeople: true,
      flagForDeletePeople: false,
      flagForAddPeopleBoys: true,
      flagForDeletePeopleBoys: false,
      flagForAddPeopleBabys: true,
      flagForDeletePeopleBabys: false,
      currentIndexAdult: 0,
      currentIndexBoys: 0,
      currentIndexBabys: 0,
      maxPeopleLocal: 9999
    };
  },
  computed: {
    ...mapGetters({
      selectedPeople: reservationTypes.getters.selectedPeople,
      minimunPeople: reservationTypes.getters.minimunPeoples,
      selectedDate: reservationTypes.getters.selectedDate,
      dataReservationEdit: reservationTypes.getters.dataReservationEdit,
      eventPrice: eventTypes.getters.eventPrice,
      eventTextWithPayment: eventTypes.getters.eventTextWithPayment,
      event: eventTypes.getters.eventFull
    }),
    canIncrement () {
      if (this.params.activeSelectorBoy === 1 || this.params.activeSelectorBaby === 1) {
        return this.currentIndexAdult + 1 <= this.limitedPeople(this.params);
      } else {
        return this.currentIndexAdult + 10 <= this.limitedPeople(this.params);
      }
    },
    canIncrementBoys () {
      return this.currentIndexBoys + 1 <= this.limitedPeople(this.params);
    },
    canIncrementBabys () {
      return this.currentIndexBabys + 1 <= this.limitedPeople(this.params);
    },
    canDecrement () {
      if (this.params.activeSelectorBoy === 1 || this.params.activeSelectorBaby === 1) {
        return this.currentIndexAdult - 1 >= 0;
      } else {
        return this.currentIndexAdult - 10 >= 0;
      }
    },
    canDecrementBoys () {
      return this.currentIndexBoys - 1 >= 0;
    },
    canDecrementBabys () {
      return this.currentIndexBabys - 1 >= 0;
    },
    gridColumnsAuto () {
      return () => {
        if (this.flagForDeletePeople && this.arrayPeopleForSelect.length === 1) {
          return 'people-quantity-new__container__grid-3';
        }
        if (!this.flagForDeletePeople && this.arrayPeopleForSelect.length === 1) {
          return 'people-quantity-new__container__grid-2';
        }
        if (this.flagForDeletePeople && this.arrayPeopleForSelect.length === 2) {
          return 'people-quantity-new__container__grid-4';
        }
        if (!this.flagForDeletePeople && this.arrayPeopleForSelect.length === 2) {
          return 'people-quantity-new__container__grid-3';
        }
      };
    },
    gridColumnsAutoBoysAdults () {
      return () => {
        if (this.flagForDeletePeople && this.arrayPeopleForSelect.length === 1) {
          return 'people-quantity-new__container__grid-3';
        }
        if (!this.flagForDeletePeople && this.arrayPeopleForSelect.length === 1) {
          return 'people-quantity-new__container__grid-2';
        }
        if (this.flagForDeletePeople && this.arrayPeopleForSelect.length === 2) {
          return 'people-quantity-new__container__grid-4';
        }
        if (!this.flagForDeletePeople && this.arrayPeopleForSelect.length === 2) {
          return 'people-quantity-new__container__grid-3';
        }
        if (this.flagForDeletePeople && this.arrayPeopleForSelect.length === 3) {
          return 'people-quantity-new__container__grid-5';
        }
        if (!this.flagForDeletePeople && this.arrayPeopleForSelect.length === 3) {
          return 'people-quantity-new__container__grid-4';
        }
        if (this.flagForDeletePeople && this.arrayPeopleForSelect.length === 4) {
          return 'people-quantity-new__container__grid-6';
        }
        if (!this.flagForDeletePeople && this.arrayPeopleForSelect.length === 4) {
          return 'people-quantity-new__container__grid-5';
        }
      };
    },
    gridColumnsAutoBoys () {
      return () => {
        if (this.flagForDeletePeopleBoys && this.arrayPeopleForSelectBoys.length === 1) {
          return 'people-quantity-new__container__grid-3';
        }
        if (!this.flagForDeletePeopleBoys && this.arrayPeopleForSelectBoys.length === 1) {
          return 'people-quantity-new__container__grid-2';
        }
        if (this.flagForDeletePeopleBoys && this.arrayPeopleForSelectBoys.length === 2) {
          return 'people-quantity-new__container__grid-4';
        }
        if (!this.flagForDeletePeopleBoys && this.arrayPeopleForSelectBoys.length === 2) {
          return 'people-quantity-new__container__grid-3';
        }
        if (this.flagForDeletePeopleBoys && this.arrayPeopleForSelectBoys.length === 3) {
          return 'people-quantity-new__container__grid-5';
        }
        if (!this.flagForDeletePeopleBoys && this.arrayPeopleForSelectBoys.length === 3) {
          return 'people-quantity-new__container__grid-4';
        }
      };
    },
    gridColumnsAutoBabys () {
      return () => {
        if (this.flagForDeletePeopleBabys && this.arrayPeopleForSelectBabys.length === 1) {
          return 'people-quantity-new__container__grid-3';
        }
        if (!this.flagForDeletePeopleBabys && this.arrayPeopleForSelectBabys.length === 1) {
          return 'people-quantity-new__container__grid-2';
        }
        if (this.flagForDeletePeopleBabys && this.arrayPeopleForSelectBabys.length === 2) {
          return 'people-quantity-new__container__grid-4';
        }
        if (!this.flagForDeletePeopleBabys && this.arrayPeopleForSelectBabys.length === 2) {
          return 'people-quantity-new__container__grid-3';
        }
        if (this.flagForDeletePeopleBabys && this.arrayPeopleForSelectBabys.length === 3) {
          return 'people-quantity-new__container__grid-5';
        }
        if (!this.flagForDeletePeopleBabys && this.arrayPeopleForSelectBabys.length === 3) {
          return 'people-quantity-new__container__grid-4';
        }
      };
    },
    computedClassBoys () {
      return () => [
        {
          ' people-quantity-new__container-boys-babys-aux': this.params.activeSelectorBoy === 1 && this.params.activeSelectorBaby === 1
        },
        this.gridColumnsAutoBoys()
      ];
    },
    computedClassBabys () {
      return () => [
        {
          'people-quantity-new__container-boys-babys-aux': this.params.activeSelectorBoy === 1 && this.params.activeSelectorBaby === 1
        },
        this.gridColumnsAutoBabys()
      ];
    }
  },
  watch: {
    useNewPeople () {
      this.showNewPeople = this.useNewPeople;
    },
    eventMinPeople () {
      if (this.eventMinPeople > 0) {
        if (this.people < this.eventMinPeople) {
          this.people = this.eventMinPeople;
        }
        if (this.event.maxPeople > 0) {
          if (this.people > this.event.maxPeople) {
            this.people = this.event.maxPeople;
          }
        }
        this.$store.commit(reservationTypes.mutations.setMinimunPeoples, this.eventMinPeople);
      }
    },
    maxPeople () {
      this.maxPeopleLocal = this.maxPeople;
      if (this.maxPeopleLocal !== 9999 && this.people > this.maxPeopleLocal) {
        this.people = this.maxPeopleLocal;
        if (this.editPeople) {
          this.dangerPeopleExec();
        } else {
          this.$emit('dangerPeopleMaxEvent', false);
        }
      }
    },
    people () {
      if (this.people > this.limitedPeople(this.params) && !this.editPeople) {
        this.$emit('dangerPeopleEvent', true);
      } else if (this.people > this.limitedPeople(this.params) && this.editPeople) {
        this.dangerPeopleExec();
      }
    },
    selectedAdult () {
      if ((this.selectedPeople > this.limitedPeople(this.params) || this.selectedAdult > this.limitedPeople(this.params)) && !this.editPeople) {
        this.$emit('dangerPeopleEvent', true);
        // eslint-disable-next-line
      } else if (this.selectedPeople > this.limitedPeople(this.params) || this.selectedAdult > this.limitedPeople(this.params) && this.editPeople) {
        this.dangerPeopleExec();
      }
    },
    selectedBoy () {
      if (((this.selectedPeople > this.limitedPeople(this.params)) || (this.selectedBoy > this.limitedPeople(this.params))) && !this.editPeople) {
        this.$emit('dangerPeopleEvent', true);
        // eslint-disable-next-line
      } else if ((this.selectedPeople > this.limitedPeople(this.params)) || (this.selectedBoy > this.limitedPeople(this.params)) && this.editPeople) {
        this.dangerPeopleExec();
      }
    },
    selectedBaby () {
      if (((this.selectedPeople > this.limitedPeople(this.params)) || (this.selectedBaby > this.limitedPeople(this.params))) && !this.editPeople) {
        this.$emit('dangerPeopleEvent', true);
        // eslint-disable-next-line
      } else if ((this.selectedPeople > this.limitedPeople(this.params)) || (this.selectedBaby > this.limitedPeople(this.params)) && this.editPeople) {
        this.dangerPeopleExec();
      }
    },
    selectedPeople () {
      if (this.editPeople === true) {
        this.$store.commit(whiteLabelTypes.mutations.setLoadHour, true);
        this.people = this.selectedPeople;
        if (
          this.dataReservationEdit.balancePaid <= 0 ||
          this.dataReservationEdit.balancePaid === null
        ) {
          this.messageAnticipe(this.selectedPeople, this.params);
        }
        if (this.selectedDate !== null) {
          let time = new Date(this.selectedDate).getTime();
          this.$store.commit(whiteLabelTypes.mutations.setAvalibilityHour, null);
          this.$store
            .dispatch({
              type: 'whiteLabel:avalibilityHour',
              data: {
                timestamp: time,
                vendorId: this.$store.getters['whiteLabel:vendorId'],
                people: this.selectedPeople,
                zone: this.selectedZone,
                typeReservation: this.selectedTypeReservation,
                tableId: this.selectedTable
              }
            })
            .then(() => {
              this.$store.commit(whiteLabelTypes.mutations.setLoadHour, false);
            });
        }
      }
    }
  },
  methods: {
    handleClick (event) {
      event.preventDefault(); // Evita la redirección
      this.$emit('corporative', true); // Emite el evento deseado
    },
    currentBackground (number) {
      if (this.editPeople) {
        if (this.isCurrentPeople(number) && this.editPeople) {
          if (number === this.selectedPeople) {
            return this.colorWithOpacity(this.editPeople);
          }
          let colorObject = this.hexToRgb(this.design.bgColorCheckout);
          if (colorObject === null) {
            colorObject = this.hexToRgb(this.design.color1);
          }
          const colorWithOpacity = `rgba(${colorObject.r}, ${colorObject.g}, ${colorObject.b}, 0.3)`;
          return colorWithOpacity;
        }
        if (number === this.selectedPeople) {
          return this.colorWithOpacity(this.editPeople);
        }
        return '';
      }
      return '';
    },
    currentBackgroundGeneral (number, type = 'adult') {
      if (this.editPeople) {
        let colorObject = this.hexToRgb(this.design.bgColorCheckout);
        if (type === 'adult') {
          if (this.isCurrentPeopleGeneralAdult(number)) {
            if (number === this.selectedAdult) {
              return this.colorWithOpacity(this.editPeople);
            } else {
              if (colorObject === null) {
                colorObject = this.hexToRgb(this.design.color1);
              }
              const colorWithOpacity = `rgba(${colorObject.r}, ${colorObject.g}, ${colorObject.b}, 0.3)`;
              return colorWithOpacity;
            }
          }
        }
        if (type === 'boy') {
          if (this.isCurrentPeopleGeneralBoy(number) && this.editPeople) {
            if (number === this.selectedBoy) {
              return this.colorWithOpacity(this.editPeople);
            } else {
              if (colorObject === null) {
                colorObject = this.hexToRgb(this.design.color1);
              }
              const colorWithOpacity = `rgba(${colorObject.r}, ${colorObject.g}, ${colorObject.b}, 0.3)`;
              return colorWithOpacity;
            }
          }
        }
        if (type === 'baby') {
          if (this.isCurrentPeopleGeneralBaby(number) && this.editPeople) {
            if (number === this.selectedBaby) {
              return this.colorWithOpacity(this.editPeople);
            } else {
              if (colorObject === null) {
                colorObject = this.hexToRgb(this.design.color1);
              }
              const colorWithOpacity = `rgba(${colorObject.r}, ${colorObject.g}, ${colorObject.b}, 0.3)`;
              return colorWithOpacity;
            }
          }
        }
      }
      return '';
    },
    isCurrentPeople (number) {
      return number === parseInt(this.dataReservationEdit.people);
    },
    isCurrentPeopleGeneralAdult (number) {
      return number === parseInt(this.dataReservationEdit.adult);
    },
    isCurrentPeopleGeneralBoy (number) {
      return number === parseInt(this.dataReservationEdit.boy);
    },
    isCurrentPeopleGeneralBaby (number) {
      return number === parseInt(this.dataReservationEdit.baby);
    },
    getTextEvent () {
      const formatter = new Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
      });
      const price = `<b>${formatter.format(this.eventPrice)}</b>`;
      let message = this.eventTextWithPayment;
      if (message && message.indexOf('PRECIO') >= 0) {
        message = message.replace('PRECIO', price);
      }
      return message;
    },
    changePeople (people) {
      if (people >= this.minimunPeople) {
        this.$store.commit(reservationTypes.mutations.setSelectedPeople, people);
      }
    },
    incrementPeople () {
      if (this.editPeople === true) {
        if (this.selectedPeople < this.limitedPeople(this.params)) {
          this.$store.commit(reservationTypes.mutations.setSelectedPeople, parseInt(this.selectedPeople) + this.params.incrementPeople);
          this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
        } else if (this.selectedPeople === this.limitedPeople(this.params)) {
          this.$emit('dangerPeopleEvent', (this.maxPeopleLocal === 9999));
        }
      } else {
        const localPeople = parseInt(this.people);
        if (localPeople < this.limitedPeople(this.params) && localPeople < this.maxPeopleLocal) {
          this.people = localPeople + this.params.incrementPeople;
          this.$emit('people', this.people);
          this.$store.commit(reservationTypes.mutations.setSelectedPeople, this.people);
          if (this.people >= this.params.minimumChargedPeople) {
            this.$store.commit(whiteLabelTypes.mutations.setIsFree, false);
          }
        } else if (localPeople === this.limitedPeople(this.params) || localPeople === this.maxPeopleLocal) {
          this.$emit('dangerPeopleEvent', (this.maxPeopleLocal === 9999));
        }
      }
    },
    decrementPeople () {
      if (this.editPeople === true) {
        this.$store.commit(
          reservationTypes.mutations.setSelectedPeople,
          this.selectedPeople - this.params.incrementPeople
        );
        this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
      } else {
        if (this.people > this.params.allowSingleReservations) {
          this.people = this.people - this.params.incrementPeople;
          this.$emit('people', this.people);
          this.$store.commit(reservationTypes.mutations.setSelectedPeople, this.people);
          if (this.people < this.params.minimumChargedPeople) {
            this.$store.commit(whiteLabelTypes.mutations.setIsFree, true);
          }
        }
      }
    },
    decrementPeoplePombo (action, type) {
      if (type === 'adult') {
        if (action === 'add') {
          if (this.selectedAdult <= this.limitedPeople(this.params)) {
            if (this.selectedAdult !== this.limitedPeople(this.params)) {
              this.$store.commit(reservationTypes.mutations.setSelectedAdult, parseInt(this.selectedAdult) + this.params.incrementPeople);
            }
          }
        } else {
          this.$store.commit(reservationTypes.mutations.setSelectedAdult, parseInt(this.selectedAdult) - this.params.incrementPeople);
        }
        let people = this.selectedAdult + this.selectedBoy;
        this.$emit('people', people);
        this.$store.commit(reservationTypes.mutations.setSelectedPeople, parseInt(people));
        this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
        this.validateIsFreeEditReservation(people);
      }
      if (type === 'boy') {
        if (action === 'add') {
          if (this.selectedBoy <= this.limitedPeople(this.params)) {
            if (this.selectedBoy !== this.limitedPeople(this.params)) {
              this.$store.commit(reservationTypes.mutations.setSelectedBoy, parseInt(this.selectedBoy) + this.params.incrementPeople);
            }
          }
        } else {
          this.$store.commit(reservationTypes.mutations.setSelectedBoy, parseInt(this.selectedBoy) - this.params.incrementPeople);
        }
        let people = this.selectedAdult + this.selectedBoy;
        this.$emit('people', people);
        this.$store.commit(reservationTypes.mutations.setSelectedPeople, parseInt(people));
        this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
        this.validateIsFreeEditReservation(people);
      }
      if (type === 'baby') {
        if (action === 'add') {
          if (this.selectedBaby <= this.limitedPeople(this.params)) {
            if (this.selectedBaby !== this.limitedPeople(this.params)) {
              this.$store.commit(reservationTypes.mutations.setSelectedBaby, parseInt(this.selectedBaby) + this.params.incrementPeople);
            }
          }
        } else {
          this.$store.commit(reservationTypes.mutations.setSelectedBaby, parseInt(this.selectedBaby) - this.params.incrementPeople);
        }
      }
      this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
    },
    choosePeople (value, type = 'adult') {
      if (this.params.activeSelectorBoy === 1 || this.params.activeSelectorBaby === 1) {
        if (type === 'adult') {
          this.$store.commit(reservationTypes.mutations.setSelectedAdult, parseInt(value));
          let people = 0;
          if (this.params.activeSelectorBoy === 1 && this.params.activeSelectorBaby === 1) {
            people = this.selectedAdult + this.selectedBoy + this.selectedBaby;
          } else {
            people = this.selectedAdult + this.selectedBoy;
          }
          if (people > this.limitedPeople(this.params)) {
            if (this.editPeople) {
              this.dangerPeopleExec();
            } else {
              people = people - this.selectedAdult;
              this.$store.commit(reservationTypes.mutations.setSelectedAdult, 0);
              this.$emit('dangerPeopleEvent', true);
            }
            return;
          }
          this.$emit('people', people);
          this.$store.commit(reservationTypes.mutations.setSelectedPeople, parseInt(people));
          this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
        }
        if (type === 'boys') {
          if (value === this.selectedBoy && !this.editPeople) {
            this.$store.commit(reservationTypes.mutations.setSelectedBoy, 0);
          } else {
            this.$store.commit(reservationTypes.mutations.setSelectedBoy, parseInt(value));
          }
          let people = 0;
          if (this.params.activeSelectorBoy === 1 && this.params.activeSelectorBaby === 1) {
            people = this.selectedAdult + this.selectedBoy + this.selectedBaby;
          } else {
            people = this.selectedAdult + this.selectedBoy;
          }
          if (people > this.limitedPeople(this.params)) {
            if (this.editPeople) {
              this.dangerPeopleExec();
            } else {
              people = people - this.selectedBoy;
              this.$store.commit(reservationTypes.mutations.setSelectedBoy, 0);
              this.$emit('dangerPeopleEvent', true);
            }
            return;
          }
          this.$emit('people', people);
          this.$store.commit(reservationTypes.mutations.setSelectedPeople, parseInt(people));
          this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
        }
        if (type === 'babys') {
          if (value === this.selectedBaby && !this.editPeople) {
            this.$store.commit(reservationTypes.mutations.setSelectedBaby, 0);
          } else {
            this.$store.commit(reservationTypes.mutations.setSelectedBaby, parseInt(value));
          }
          let people = 0;
          if (this.params.activeSelectorBoy === 1 && this.params.activeSelectorBaby === 1) {
            people = this.selectedAdult + this.selectedBoy + this.selectedBaby;
          } else {
            people = this.selectedAdult + this.selectedBaby;
          }
          if (people > this.limitedPeople(this.params)) {
            if (this.editPeople) {
              this.dangerPeopleExec();
            } else {
              people = people - this.selectedBaby;
              this.$store.commit(reservationTypes.mutations.setSelectedBaby, 0);
              this.$emit('dangerPeopleEvent', true);
            }
            return;
          }
          this.$emit('people', people);
          this.$store.commit(reservationTypes.mutations.setSelectedPeople, parseInt(people));
          this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
        }
      } else {
        if (this.editPeople) {
          this.maxPeopleLocal = 9999;
        }
        if (value <= this.limitedPeople(this.params) && value <= this.maxPeopleLocal) {
          this.people = value;
          this.$emit('people', this.people);
          this.$store.commit(reservationTypes.mutations.setSelectedPeople, this.people);
          if (this.people === this.params.minimumChargedPeople) {
            this.$store.commit(whiteLabelTypes.mutations.setIsFree, false);
          }
        } else if (value > this.limitedPeople(this.params) || value > this.maxPeopleLocal) {
          if (this.editPeople) {
            this.dangerPeopleExec();
          } else {
            this.$emit('dangerPeopleMaxEvent', false);
          }
        }
      }
    },
    incrementPeopleNew (type = 'adult') {
      switch (type) {
        case 'adult':
          this.updatePeopleArray('add', type);
          break;
        case 'boys':
          this.updatePeopleArray('add', type);
          break;
        case 'babys':
          this.updatePeopleArray('add', type);
          break;
        default:
          break;
      }
    },
    decrementPeopleNew (type = 'adult') {
      switch (type) {
        case 'adult':
          this.updatePeopleArray('delete', type);
          break;
        case 'boys':
          this.updatePeopleArray('delete', type);
          break;
        case 'babys':
          this.updatePeopleArray('delete', type);
          break;
        default:
          break;
      }
    },
    updatePeopleArray (mode = 'add', type = 'adult') {
      let initialNumber = this.params.allowSingleReservations; // Valor inicial, puede ser dinámico según lo necesites
      let increment = 0; // Incremento fijo de 10 en 10
      if (this.params.activeSelectorBoy === 0 && this.params.activeSelectorBaby === 0) {
        increment = 10;
      }
      if (this.params.activeSelectorBoy === 1 || this.params.activeSelectorBaby === 1) {
        if (this.params.activeSelectorBoy === 1 && this.params.activeSelectorBaby === 1) {
          if (type === 'adult') {
            increment = 4;
          }
          if (type === 'boys' || type === 'babys') {
            increment = 1;
          }
        } else {
          if (type === 'adult' || type === 'boys' || type === 'babys') {
            increment = 3;
          }
        }
      }
      if (mode === 'add') {
        let maxNumber = this.limitedPeople(this.params);
        let continueNumber;
        if (type === 'adult') {
          if (this.arrayPeopleForSelect.length > 0) {
            initialNumber = this.arrayPeopleForSelect[this.arrayPeopleForSelect.length - 1].value + 1;
          }
        }
        if (type === 'boys') {
          if (this.arrayPeopleForSelectBoys.length > 0) {
            initialNumber = this.arrayPeopleForSelectBoys[this.arrayPeopleForSelectBoys.length - 1].value + 1;
          }
        }
        if (type === 'babys') {
          if (this.arrayPeopleForSelectBabys.length > 0) {
            initialNumber = this.arrayPeopleForSelectBabys[this.arrayPeopleForSelectBabys.length - 1].value + 1;
          }
        }
        const initialCalc = initialNumber + increment - 1;
        continueNumber = Math.min(initialCalc, maxNumber);
        if (initialCalc < maxNumber + increment) {
          if (type === 'adult') {
            if (Math.abs(this.params.allowSingleReservations - continueNumber) + 1 > increment + 1) {
              this.flagForDeletePeople = true;
            }
            this.arrayPeopleForSelect = [];
            for (let index = initialNumber; index <= continueNumber; index++) {
              this.arrayPeopleForSelect.push({
                value: index
              });
            }
          }
          if (this.params.activeSelectorBoy === 1 || this.params.activeSelectorBaby === 1) {
            if (type === 'boys') {
              if (Math.abs(this.params.allowSingleReservations - continueNumber) + 1 > increment + 1) {
                this.flagForDeletePeopleBoys = true;
              }
              this.arrayPeopleForSelectBoys = [];
              for (let index = initialNumber; index <= continueNumber; index++) {
                this.arrayPeopleForSelectBoys.push({
                  value: index
                });
              }
            }
            if (type === 'babys') {
              if (Math.abs(this.params.allowSingleReservations - continueNumber) + 1 > increment + 1) {
                this.flagForDeletePeopleBabys = true;
              }
              this.arrayPeopleForSelectBabys = [];
              for (let index = initialNumber; index <= continueNumber; index++) {
                this.arrayPeopleForSelectBabys.push({
                  value: index
                });
              }
            }
            if (this.params.activeSelectorBoy === 1 && this.params.activeSelectorBaby === 1) {
              if (type === 'boys') {
                this.flagForDeletePeopleBoys = true;
              }
              if (type === 'babys') {
                this.flagForDeletePeopleBabys = true;
                this.arrayPeopleForSelectBabys = [];
                for (let index = initialNumber; index <= continueNumber; index++) {
                  this.arrayPeopleForSelectBabys.push({
                    value: index
                  });
                }
              }
            }
          }
        } else {
          if (type === 'adult') {
            this.flagForAddPeople = false;
          }
          if (type === 'boys') {
            this.flagForAddPeopleBoys = false;
          }
          if (type === 'babys') {
            this.flagForAddPeopleBabys = false;
          }
          if (this.editPeople) {
            this.dangerPeopleExec();
          } else {
            this.$emit('dangerPeopleEvent', true);
          }
        }
      } else if (mode === 'delete') {
        let continueNumber = 0;
        if (type === 'adult') {
          this.flagForAddPeople = true;
          continueNumber = this.arrayPeopleForSelect[0].value - 1;
        }
        if (type === 'boys') {
          this.flagForAddPeopleBoys = true;
          continueNumber = this.arrayPeopleForSelectBoys[0].value - 1;
        }
        if (type === 'babys') {
          this.flagForAddPeopleBabys = true;
          continueNumber = this.arrayPeopleForSelectBabys[0].value - 1;
        }
        let initialNumber = continueNumber - increment;
        if (initialNumber === this.params.allowSingleReservations) {
          if (type === 'adult') {
            this.flagForDeletePeople = false;
          }
          if (type === 'boys') {
            this.flagForDeletePeopleBoys = false;
          }
          if (type === 'babys') {
            this.flagForDeletePeopleBabys = false;
          }
        } else {
          if (this.params.activeSelectorBoy === 1 || this.params.activeSelectorBaby === 1) {
            if (initialNumber === 1) {
              if (type === 'boys') {
                this.flagForDeletePeopleBoys = false;
              }
              if (type === 'babys') {
                this.flagForDeletePeopleBabys = false;
              }
              initialNumber = continueNumber - increment;
            } else {
              initialNumber = continueNumber - increment + 1;
            }
          } else {
            initialNumber = continueNumber - increment + 1;
          }
        }
        if (initialNumber > 0) {
          if (type === 'adult') {
            this.arrayPeopleForSelect = [];
            for (let index = initialNumber; index <= continueNumber; index++) {
              this.arrayPeopleForSelect.push({
                value: index
              });
            }
          }
          if (type === 'boys') {
            this.arrayPeopleForSelectBoys = [];
            for (let index = initialNumber; index <= (continueNumber); index++) {
              this.arrayPeopleForSelectBoys.push({
                value: index
              });
            }
          }
          if (type === 'babys') {
            this.arrayPeopleForSelectBabys = [];
            for (let index = initialNumber; index <= continueNumber; index++) {
              this.arrayPeopleForSelectBabys.push({
                value: index
              });
            }
          }
        } else {
          if (this.editPeople) {
            this.dangerPeopleExec();
          } else {
            this.$emit('dangerPeopleEvent', true);
          }
        }
      }
    },
    setDefaultInitialPeople () {
      let quantityPeople = this.initialPeople;
      let quantityPeopleAux = this.initialPeople;
      let limitPeople = 0;
      if (this.params.activeSelectorBoy === 1 || this.params.activeSelectorBaby === 1) {
        if (this.params.activeSelectorBoy === 1 && this.params.activeSelectorBaby === 1) {
          if ((this.limitedPeople(this.params) - this.params.allowSingleReservations) >= 5) {
            limitPeople = 4 + (this.limitedPeople(this.params) - (this.limitedPeople(this.params) - this.params.allowSingleReservations) - 1);
          } else {
            limitPeople = this.limitedPeople(this.params) - 1;
          }
        } else {
          if ((this.limitedPeople(this.params) - this.params.allowSingleReservations) >= 4) {
            limitPeople = 3 + (this.limitedPeople(this.params) - (this.limitedPeople(this.params) - this.params.allowSingleReservations) - 1);
          } else {
            limitPeople = this.limitedPeople(this.params) - 1;
          }
        }
        for (let index = this.params.allowSingleReservations - 1; index <= limitPeople; index++) {
          this.arrayPeopleForSelect.push({
            value: index + 1
          });
        }
      } else {
        if ((this.limitedPeople(this.params) - this.params.allowSingleReservations) >= 11) {
          quantityPeopleAux = 11 + (this.limitedPeople(this.params) - (this.limitedPeople(this.params) - this.params.allowSingleReservations) - 1);
        } else {
          quantityPeopleAux = this.limitedPeople(this.params);
        }
        if (this.params.activeSelectorBoy === 1 || this.params.activeSelectorBaby === 1) {
          for (let index = this.params.allowSingleReservations - 1; index <= quantityPeopleAux; index++) {
            this.arrayPeopleForSelect.push({
              value: index + 1
            });
          }
        } else {
          for (let index = this.params.allowSingleReservations - 1; index < quantityPeopleAux; index++) {
            this.arrayPeopleForSelect.push({
              value: index + 1
            });
          }
        }
      }
      if (this.params.activeSelectorBoy === 1 || this.params.activeSelectorBaby === 1) {
        quantityPeople = this.params.activeSelectorBoy === 1 && this.params.activeSelectorBaby === 1 ? 5 : 4;
        if (quantityPeopleAux < quantityPeople) {
          quantityPeople = quantityPeopleAux;
        }
      }
      if (this.params.activeSelectorBoy === 1 || this.params.activeSelectorBaby === 1) {
        for (let index = 0; index < quantityPeople; index++) {
          this.arrayPeopleForSelectBoys.push({
            value: index + 1
          });
          this.arrayPeopleForSelectBabys.push({
            value: index + 1
          });
        }
        if (this.arrayPeopleForSelectBoys[this.arrayPeopleForSelectBoys.length - 1].value === quantityPeopleAux) {
          this.flagForAddPeopleBoys = false;
        }
        if (this.arrayPeopleForSelectBabys[this.arrayPeopleForSelectBabys.length - 1].value === quantityPeopleAux) {
          this.flagForAddPeopleBabys = false;
        }
      }
      if (this.params.activeSelectorBoy === 1 && this.params.activeSelectorBaby === 1) {
        this.arrayPeopleForSelectBoys = [];
        this.arrayPeopleForSelectBabys = [];
        const limitAux = quantityPeopleAux < 2 ? quantityPeopleAux : 2;
        for (let index = 0; index < limitAux; index++) {
          this.arrayPeopleForSelectBoys.push({
            value: index + 1
          });
          this.arrayPeopleForSelectBabys.push({
            value: index + 1
          });
        }
        if (this.arrayPeopleForSelectBoys[this.arrayPeopleForSelectBoys.length - 1].value === quantityPeopleAux) {
          this.flagForAddPeopleBoys = false;
        } else {
          this.flagForAddPeopleBoys = true;
        }
        if (this.arrayPeopleForSelectBabys[this.arrayPeopleForSelectBabys.length - 1].value === quantityPeopleAux) {
          this.flagForAddPeopleBabys = false;
        } else {
          this.flagForAddPeopleBabys = true;
        }
      }
      if (this.limitedPeople(this.params) <= quantityPeople + this.params.allowSingleReservations - 1) {
        this.flagForAddPeople = false;
      }
    },
    validateIsFreeEditReservation (people) {
      const validationChargePeople = people >= this.params.minimumChargedPeople;
      if ((validationChargePeople && this.dataReservationEdit.decoration === 1) || (this.dataReservationEdit.decoration === 0 && validationChargePeople)) {
        this.$store.commit(whiteLabelTypes.mutations.setIsFree, false);
      } else {
        this.$store.commit(whiteLabelTypes.mutations.setIsFree, true);
      }
    },
    dangerPeopleExec () {
      let messageCustom = '';
      const icon = "<p style='display:contents'>";
      if (this.vendor.id !== 227) {
        messageCustom = this.params.messageCustomValidatePerson === null
          ? 'Si deseas realizar una reserva para un número mayor de personas debes comunicarte al <strong>Teléfono:' + this.vendor.phone + ' </strong> '
          : this.params.messageCustomValidatePerson.replace('<p>', icon);
        this.$buefy.snackbar.open({
          duration: 50000,
          message: "<i class='fa fa-exclamation-circle alerticon '></i>" + messageCustom,
          type: 'is-danger',
          position: 'is-top-right',
          actionText: 'X'
        });
      } else {
        messageCustom = this.params.messageCustomValidatePerson === null
          ? 'Si deseas realizar una reserva para un número mayor de personas debes comunicarte al <strong>Teléfono: 313 8369436 </strong>, para eventos llamar al <strong>322 4815200</strong> '
          : this.params.messageCustomValidatePerson.replace('<p>', icon);
        this.$buefy.snackbar.open({
          duration: 50000,
          message: "<i class='fa fa-exclamation-circle alerticon '></i>" + messageCustom,
          type: 'is-danger',
          position: 'is-top-right',
          actionText: 'X'
        });
      }
    }
  },
  mounted () {
    if (this.maxPeople) {
      this.maxPeopleLocal = this.maxPeople;
    }
    if (this.limitedPeople(this.params) < this.initialPeople) {
      this.initialPeople = this.limitedPeople(this.params);
    }
    this.setDefaultInitialPeople();
    this.showNewPeople = this.useNewPeople;
    this.$store.commit(reservationTypes.mutations.setMinimunPeoples, this.params.allowSingleReservations);
    this.messagePeople = JSON.parse(this.params.messagePeople);
    let data = this.$route.query;
    if (data.selectedPeople) {
      this.people = data.selectedPeople;
      this.$emit('people', data.selectedPeople);
    } else {
      this.people = this.params.allowSingleReservations;
      this.$emit('people', this.people);
    }
    if (this.editPeople === true) {
      if (this.infoReservation.balancePaid > 0) {
        this.$store.commit(reservationTypes.mutations.setMinimunPeoples, this.dataReservationEdit.people);
      }
      this.people = this.dataReservationEdit.people;
      this.minimumChargedPeople =
        this.params.allowSingleReservations == null ? 2 : 1;
      this.messageAnticipe(this.dataReservationEdit.people, this.params);
      this.$store.commit(whiteLabelTypes.mutations.setIsFree, this.isFrees);
      this.$store.commit(
        reservationTypes.mutations.setSelectedTypeReservation,
        this.dataReservationEdit.typeReservation
      );
      if (this.dataReservationEdit.typeReservation === 'Pombo' || this.params.vendorId === 118 || this.params.vendorId === 231 || this.params.activeSelectorBoy || this.params.activeSelectorBaby) {
        this.$store.commit(
          reservationTypes.mutations.setSelectedPeople,
          parseInt(this.dataReservationEdit.people)
        );
        this.$store.commit(
          reservationTypes.mutations.setSelectedAdult,
          (this.params.activeSelectorBoy === 1 || this.params.activeSelectorBaby === 1 ? parseInt(this.dataReservationEdit.adult) : parseInt(this.dataReservationEdit.people))
        );
        this.$store.commit(
          reservationTypes.mutations.setSelectedBoy,
          parseInt(this.dataReservationEdit.boy)
        );
        this.$store.commit(
          reservationTypes.mutations.setSelectedBaby,
          parseInt(this.dataReservationEdit.baby)
        );
      }
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/Select/_people.scss";
</style>
